<template>
  <section class="invoice-add-wrapper">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        variant="success"
        label="Spinning"
        block
        v-if="loading"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <b-form
      @submit.prevent="submitForm"
      @change="submitFormText"
      v-if="!loading"
    >
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <!-- <Logo /> -->

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <h4 class="invoice-title mr-1">Reference</h4>
                    <b-input-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="templateId.reference"
                        readonly
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">Start Date: </span>
                    <b-form-datepicker
                      id="example-datepicker1"
                      v-model="templateId.startDate"
                      :min="today"
                      date-format="yyyy-MM-dd"
                      class="mb-1"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <b-form-datepicker
                      id="example-datepicker2"
                      v-model="templateId.dueDate"
                      :min="today"
                      date-format="yyyy-MM-dd"
                      class="mb-1"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                    >Staff:</label
                  >
                  <b-form-select
                    v-model="templateId.assignedTo"
                    :options="optionsSalesPerson"
                    required
                  />
                </b-col>

                <!-- Col: Total -->
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <h4 class="my-2 d-flex justify-content-start mx-3">
              <span>{{ product.description }}</span>
            </h4>

            <!-- Invoice Client & Payment Details -->

            <!-- Items Section -->

            <b-card-body class="invoice-padding form-item-section">
              <!-- <b-form-textarea v-model="templateId.template" rows="25" /> -->
              <quill-editor v-model="renderedTemplate" :options="editorOption">
                <div id="toolbar" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-underline">Underline</button>
                  <button class="ql-strike">Strike</button>
                  <button class="ql-blockquote">Quote</button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                  <button class="ql-background"></button>
                  <select class="ql-font">
                    <option selected="selected"></option>
                    <option value="serif">serif</option>
                    <option value="monospace">monospace</option>
                  </select>
                  <select class="ql-header">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option selected></option>
                  </select>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </div>
              </quill-editor>
            </b-card-body>

            <b-card-body
              class="invoice-padding form-item-section"
              title="Note for Service Request"
              style="text-align: left"
            >
              <!-- <b-form-textarea v-model="templateId.notes" rows="5" /> -->
              <quill-editor v-model="templateId.notes" :options="editorOptionNote">
                <div id="toolbar-note" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-underline">Underline</button>
                  <button class="ql-strike">Strike</button>
                  <button class="ql-blockquote">Quote</button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                  <button class="ql-background"></button>
                  <select class="ql-font">
                    <option selected="selected"></option>
                    <option value="serif">serif</option>
                    <option value="monospace">monospace</option>
                  </select>
                  <select class="ql-header">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option selected></option>
                  </select>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </div>
              </quill-editor>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckbox,
  BPopover,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BSpinner,
    BFormSelect,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormDatepicker,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    quillEditor,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      startDate: new Date(),
      dueDate: null,
      textAreaRows: 1,

      config: {
        wrap: {
          wrap: true,
          dateFormat: "Y-m-d",
          minDate: new Date(),
        },
      },
      configDue: {
        wrap: {
          wrap: true,
          dateFormat: "Y-m-d",
          minDate: new Date(),
        },
      },
      salesPerson: "",
      optionsSalesPerson: [],
      template: {
        name: "",
        startDate: new Date(),
        dueDate: null,
        salesPersonId: "",
        text: "",
      },
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOptionNote: {
        modules: {
          toolbar: "#toolbar-note",
        },
      },
    };
  },

  props: {
    templateId: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      product: "productID",
      productLoading: "loading",
    }),
    today() {
      const today = new Date();
      return today.toISOString().slice(0, 10);
    },
    renderedTemplate: {
      get() {
        return this.templateId && this.templateId.template ? this.templateId.template.replace(/\n/g, "<br>") : '';
      },
      set(newVal) {
        this.templateId.template = newVal.replace(/<br>/g, "\n");
      }
    }
  },
  watch: {
    renderedTemplate: function(newVal, oldVal) {
      this.templateId.template = newVal.replace(/<br>/g, "\n");
      this.submitFormText();
    }
  },
  async mounted() {
   
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
    this.submitFormText();
  },

  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("productModule", ["getProductIDAction"]),

    submitFormText() {
      this.$emit("onchange", this.templateId);
    },
    getSelectedSalesPerson(event) {
      this.template.salesPersonId = event;
    },
    updateTextAreaRows() {
      // Get the number of newline characters in the text area's value
      const newlines = (this.productID.letterDescription.match(/\n/g) || [])
        .length;
      // Set the number of rows to the number of newlines + 1
      this.textAreaRows = newlines + 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.text-area-auto-height {
  height: auto;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
